import React from "react"
import BlogHelper from "../helpers/blogposts"
import BlogItemTag from "./blog_item_tag"
import BlogCategoryList from "./blog_category_list"
import BlogTagList from "./blog_tag_list"
import ShareButtons from "./share_buttons"

export default function BlogItemSidebar(props) {
    const itemColorsObj = BlogHelper.getItemCardColors(props.category)

    let tags = [];
    props.tags.map((element, index) => {
      const tagInfo = BlogHelper.getTagInfo(element)
      if (tagInfo){
        tagInfo.isLast = false;

        if (index + 1 == props.tags.length){
          tagInfo.isLast = true;
        }
        tags.push(<BlogItemTag key={index} tag={tagInfo} />);
      }
    });

    const imgFileName = BlogHelper.getCDNImgPath(props.featuredImage)

    return (
        <>
        <div className="block_box">
      <div className="service-page-box-row first-box">
        <div className="service-page-box-row-img">
            <div className="service-page-box-row-img-first">
              <img className="bg_img_blog" src={imgFileName} alt={props.featuredImageAltText} />
              <img
                className="bg_img-icon"
                src={itemColorsObj.icon}
                alt="icon"
              />
              <div className="first-img-text">
                <span>
                  <small style={itemColorsObj.style}>{props.categoryTitle}</small>
                </span>
              </div>
            </div>
        </div>
        <div className="service-page-box-row-text">
          <h4 className="book-svg">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="folder-open"
              className="svg-inline--fa fa-folder-open fa-w-18"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"
              />
            </svg>{" "}
            <span style={itemColorsObj.style}>{props.categoryTitle}</span>
          </h4>
          <h3>{props.htmlTitle}</h3>
          <ul className="rejoindre-page-detail-left-ul">
            <li>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z" />
                </svg>
              </span>
              <p>
                {tags}
              </p>
            </li>
            <li>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z" />
                </svg>
              </span>
              <p style={{ fontFamily: '"gothamblack"' }}> {props.date}</p>
            </li>
          </ul>
          <ul className="social_links">
            <b> {props.shareTitle}</b>
            <ShareButtons
                title={props.share_title}
                url={props.share_url}
                twitterHandle={props.share_twitter_handle}
                tags={props.share_twitter_tags}
              />
          </ul>
          <div className="all-filter">
            <div className="blog-page-filter">
              <hr/>
              <h2>{props.sidebarNavTitle}</h2>
              <h3>{props.sidebarCategoriesTitle}</h3>
              <ul>
                <BlogCategoryList
                  language={props.language}
                />
              </ul>
            </div>

            <div className="blog-page-filter">
              <h3>{props.sidebarTagsTitle}</h3>
              <ul className="mots">
                <BlogTagList
                  language={props.language}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )
}
